import { GET_REGISTRATION_METADATA } from './actionType';
import { RegistrationMetaData } from '../type/Type';

const INITIAL_STATE: any = {};

export default function (state: RegistrationMetaData = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_REGISTRATION_METADATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

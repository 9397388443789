import {
    FETCH_USER_REGISTRATION, SYNC_REGISTRATION_DATA,
    DELETE_REGISTRATION_DATA,
    FETCH_ONLY_USER_REGISTRATION,
} from './actionType';
import { RegistrationData } from '../type/Type';

const INITIAL_STATE: RegistrationData[] = []; // Initialize as an empty array

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case FETCH_USER_REGISTRATION:
            return action.payload;

        case FETCH_ONLY_USER_REGISTRATION:
            return action.payload;

        case SYNC_REGISTRATION_DATA:
            return state;

        case DELETE_REGISTRATION_DATA:
            // Filter out the item with the matching orgId
            return state.filter((data) => data.orgId !== action.payload);

        default:
            return state;

    }
}


import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import * as registrationMetadataAction from '../../reducers/registrationMetaDataAction';
import * as registrationDataAction from '../../reducers/registrationDataAction';
import './RegistrationDashboard.css';
import ProgressSteps from '../ProgressSteps/ProgressSteps';
import Spinner from '../Spinner/Spinner';
import { AppState } from '../../type/Type';
import { RegistrationData, RegistrationMetaData, DateEntry } from '../../type/Type';
import {
  Paper,
  CssBaseline,
  Grid,
  Typography,
  FormControl,
  Button,
  Select,
  MenuItem,
  Avatar,
  TextField,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Badge from '@material-ui/core/Badge'; // From material-ui core
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { parse, format, isValid } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: any) => ({
  badge: {
    '& .MuiBadge-badge': {
      right: 0,
      bottom: -5, // Adjust to position the badge below the date box
      fontSize: '0.6rem',
      height: '1rem',
      minWidth: '1rem',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  day: (props: any) => ({
    color: props.isAvailable ? 'red' : theme.palette.text.disabled,
    opacity: props.isAvailable ? 1 : 0.5,
    textAlign: 'center' as const,
    lineHeight: '2em',
    fontSize: '0.875rem',
    width: '2.5em',
    height: '2.5em',
    margin: '0 auto',
    borderRadius: '4px', // Box styling
    border: props.isAvailable ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.action.disabled}`,
    position: 'relative' as const,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
});





class Registration extends Component<any, any> {


  constructor(props: any) {
    super(props);

    this.state = {
      progressStep: 0, // Update this variable to reflect the current step dynamically
      data: null,
      isLoading: true,
      registrationData: {
        orgId: (props.profile && props.profile.orgId) || '',
        dates: [],
        paymentOption: '',
        hubAmount: '',
        hubComment: '',
        zabihatCount: 0,
        zabihatMonths: [],
      },
      registrationMetaData: {
        type: '',
        orgId: '',
        amount: [],
        dates: [],
      },
      validationErrors: {},
      dateEntry: {
        value: '',
        count: 0,
      },
      userOrgMetadata: [],
      allDates: [],
      availableDates: [],
      dateEntries: [
        // { value: format(new Date(), 'eeee, d MMMM yyyy'), count: 0 }, // Example initial entry
      ],
      dateCount: 0,
      showDialog: false,
      successMessage: '',
      errorMessage: '',
      registrationMessage: '',
      registrationStatus: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.handleDateDelete = this.handleDateDelete.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  handleDateDelete(dateValue: string) {
    this.setState((prevState) => {
      // Find the index of the first occurrence of the date to be deleted
      const indexToRemove = prevState.dateEntries.findIndex((entry) => entry.value === dateValue);
      // If the date is found, remove only the first occurrence
      let updatedDateEntries = [...prevState.dateEntries]; // Create a shallow copy of the dateEntries array
      if (indexToRemove !== -1) {
        updatedDateEntries.splice(indexToRemove, 1); // Remove only the first occurrence
      }


      // Find the restored date object from the original state
      const restoredDate = prevState.dateEntries.find((entry) => entry.value === dateValue);

      // If the restored date is valid, update `availableDates`
      let updatedAvailableDates = [...prevState.availableDates]; // Create a shallow copy of availableDates
      if (restoredDate) {
        updatedAvailableDates = updatedAvailableDates.map((dateEntry) => {
          if (
            dateEntry.value === format(
              parse(restoredDate.value, 'EEEE, d MMMM yyyy', new Date()),
              'MM-dd-yyyy'
            )
          ) {
            // Increment the count of the matching available date
            return { ...dateEntry, count: dateEntry.count + restoredDate.count };
          }
          return dateEntry; // Leave other entries unchanged
        });

        // If the restored date doesn't exist in `availableDates`, add it
        const restoredDateFormatted = {
          value: format(
            parse(restoredDate.value, 'EEEE, d MMMM yyyy', new Date()),
            'MM-dd-yyyy'
          ),
          count: restoredDate.count,
        };

        if (!updatedAvailableDates.some((date) => date.value === restoredDateFormatted.value)) {
          updatedAvailableDates.push(restoredDateFormatted);
        }
      }


      return {
        dateEntries: updatedDateEntries, // Update the dateEntries array with the removed date
        availableDates: updatedAvailableDates, // Restore the count in availableDates
      };
    });
  }

  handleInputChange(event: any) {
    const { name, value } = event.target;

    if (name === "hubAmount") {
      const ranges = [
        { min: 0, max: 2350, count: 0 },
        { min: 2350, max: 4700, count: 1 },
        { min: 4700, max: 7050, count: 2 },
        { min: 7050, max: 9400, count: 3 },
        { min: 9400, max: 11750, count: 4 },
        { min: 11750, max: 14100, count: 5 },
        { min: 14100, max: 16450, count: 6 },
        { min: 16450, max: 18800, count: 7 },
        { min: 18800, max: 21150, count: 8 },
        { min: 21150, max: 23500, count: 9 },
        { min: 23500, max: 25850, count: 10 },
      ];
      const amount = parseFloat(value);
      let count = 0; // Default count

      // Loop through ranges and find the correct count
      ranges.forEach((range) => {
        if (amount >= range.min && amount < range.max) {
          count = range.count;
        }
      });

      if (this.state.dateEntries.length > 0) {
        this.state.dateEntries.forEach((date) => {
          this.handleDateDelete(date.value);
        });
      }

      // Update the state with transformed allDates and other changes
      this.setState((prevState) => ({
        registrationData: {
          ...prevState.registrationData,
          [name]: value,
        },
        dateEntries: [],
        dateCount: count,
      }));
    } else {
      console.log(name, value);
      // Handle other input changes
      this.setState((prevState) => ({
        registrationData: {
          ...prevState.registrationData,
          [name]: value,
        },
      }));
    }
  }

  handleDateSelection(date: Date) {
    if (!date) return;

    const formattedDate = format(date, 'eeee, d MMMM yyyy'); // Format the date

    this.setState((prevState) => {

      const { availableDates, dateEntries, dateCount } = prevState;


      // Find the date object in the availableDates array
      const dateObject = availableDates.find((entry) => entry.value === format(date, 'MM-dd-yyyy'));


      // If the date is not found or its count is 0, do not allow selection
      if (!dateObject || dateObject.count === 0) return {};
      // Prevent adding more dates than the allowed dateCount
      if (dateEntries.length >= dateCount) return {};

      // Update the count for the selected date
      const updatedAvailableDates = availableDates.map((entry) => {
        if (entry.value === format(date, 'MM-dd-yyyy')) {
          return { ...entry, count: entry.count - 1 }; // Decrement the count
        }
        return entry;
      });


      // Add the selected date to dateEntries, allowing duplicates for the same day
      const updatedDateEntries = [...dateEntries, { value: formattedDate, count: 1 }];

      return {
        availableDates: updatedAvailableDates, // Update availableDates with the decremented count
        dateEntries: updatedDateEntries,      // Add the new selection to dateEntries
      };
    });
  }

  handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    const { registrationData = {}, dateEntries } = this.state;
    const updatedRegistrationData = { ...registrationData };

    if (this.validateForm()) {
      // Populate registrationData with necessary defaults or state values
      updatedRegistrationData.dates = dateEntries || [];
      updatedRegistrationData.orgId = this.props.profile.orgId;
      if (updatedRegistrationData.zabihatCount == 0) {
        updatedRegistrationData.zabihatMonths = [];
      }

      switch (updatedRegistrationData.paymentOption) {
        case 'qardan hasana application':
          updatedRegistrationData.paymentOption = 'qh';
          break;
        case 'one cheque':
          updatedRegistrationData.paymentOption = 'onecheque';
          break;
        case 'two cheques':
          updatedRegistrationData.paymentOption = 'twocheque';
          break;
        default:
          console.error('Invalid payment option selected');
          break;
      }

      const errors: Partial<Record<keyof RegistrationData, string>> = {};
      if (!updatedRegistrationData.orgId) {
        errors.orgId = 'Organization ID is required';
      }

      this.setState({ validationErrors: errors });

      // Proceed only if there are no validation errors
      if (Object.keys(errors).length === 0) {
        this.props.updateRegistrationData(
          updatedRegistrationData,
          (response) => {

            if (response === "{'result': 'Success'}") {
              this.setState({
                successMessage: 'Thank you for completing the required information for FMB Hubb Takhmeen. FMB Finance will reach out to you if needed to discuss your FMB thaali niyaaz hub. Once your information has been reviewed by FMB Finance, a confirmation email will be sent confirming thaali renewal registration process is completed and providing all details on niyaz and zabihat payment.',
                showDialog: true,
              });
            } else {
              console.error('Unexpected result in success callback:', response);
              this.setState({
                errorMessage: response || 'Unexpected response.',
                showDialog: true,
              });
            }
          },
          (error) => {
            console.error('Error callback triggered:', error);
            this.setState({
              errorMessage: error.message || 'Failed to process the registration data.',
              showDialog: true,
            });
          }
        );
      } else {
        console.log('Form contains validation errors. Please fix them before submitting.');
      }
    }
  }

  validateForm() {
    const { registrationData = {}, dateEntries, dateCount } = this.state;
    const errors: Partial<Record<keyof RegistrationData, string>> = {};
    registrationData.dates = dateEntries || [];

    // Validation checks
    if (registrationData.dates.length !== dateCount) {
      errors.dates = `Please select exactly ${dateCount} dates`;
    }

    if (!registrationData.hubAmount) {
      errors.hubAmount = 'Hub amount is required';
    }

    if (isNaN(registrationData.zabihatCount)) {
      errors.zabihatCount = 'Zabihat count should be entered';
    }

    if (!registrationData.paymentOption) {
      errors.paymentOption = 'Payment method is required';
    }

    if (registrationData.zabihatCount > 0) {
      const invalidMonths = registrationData.zabihatMonths.filter(month => !month || month === '');

      if (invalidMonths.length > 0 || registrationData.zabihatMonths.length !== registrationData.zabihatCount) {
        errors.zabihatMonths = 'Please select the required number of zabihat months.';
      }
    }


    this.setState({ validationErrors: errors });
    return Object.keys(errors).length === 0;
  }


  handleMonthChange(event: any, index: number) {
    const { value } = event.target;

    this.setState((prevState) => {
      // Ensure zabihatMonths is an array before spreading
      const updatedMonths = Array.isArray(prevState.registrationData.zabihatMonths)
        ? [...prevState.registrationData.zabihatMonths] // If it is an array, spread it
        : []; // Otherwise, initialize it as an empty array

      updatedMonths[index] = value; // Update the value at the specified index

      return {
        registrationData: {
          ...prevState.registrationData,
          zabihatMonths: updatedMonths, // Update the state with the modified array
        },
      };
    });
  }

  async fetchExistingData() {
    try {
      // Call API or retrieve data from Redux or any source
      await this.props.getRegistrationDatabyId(this.props.profile.orgId);
      const data: RegistrationData[] = this.props.registrationData;  // Assuming the response contains the form data
      const dataArray: RegistrationData[] = Array.isArray(data) ? data : Object.values(data);
      const currentUserOrgId = this.props.profile.orgId;
      const registrationData = data;
      if (registrationData === undefined || registrationData.length === 0) {
        this.setState({
          progressStep: 0,
        });
      }
      else {
        if (this.props.profile.registrationStatus === "completed") {
          this.setState({
            registrationMessage: "Registration is approved",
            progressStep: 4,
            registrationData: registrationData,
            isLoading: false,
          });

        }
        else {
          this.setState({
            registrationData: registrationData,
            registrationMessage: "FMB Finance will reach out to you if needed to discuss your FMB thaali niyaaz hub. Once your information has been reviewed by FMB Finance, a confirmation email will be sent confirming thaali renewal registration process is completed and providing all details on niyaz and zabihat payment.",
            progressStep: 1,
            isLoading: false,
          });
        }
      }

    } catch (error) {
      console.error("Error fetching existing data:", error);
      return this.state.registrationData;
    }
  }
  async fetchRegistrationMetaData() {
    try {
      await this.props.getRegistrationMetaData();
      const data: RegistrationMetaData[] = this.props.registrationMetaData;
      const dataArray: RegistrationMetaData[] = Array.isArray(data) ? data : Object.values(data);

      const currentUserOrgId = this.props.profile.orgId;
      const userOrgMetadata = dataArray.filter(
        (item: RegistrationMetaData) => item.type === 'user' && item.orgId === currentUserOrgId
      );

      const allDates = dataArray
        .filter((item: RegistrationMetaData) => item.type === 'date' && item.dates)
        .reduce<DateEntry[]>((acc, item) => acc.concat(item.dates), []);

      const availableDates = allDates
        .filter((dateEntry: DateEntry) => dateEntry.count !== 0) // Only include dates with count !== 0
        .map((dateEntry: DateEntry) => {
          // Parse the date string into a Date object
          const parsedDate = parse(dateEntry.value, 'EEEE, d MMMM yyyy', new Date());

          // Format the Date object into 'MM-dd-yyyy'
          const formattedDate = format(parsedDate, 'MM-dd-yyyy');

          return {
            value: formattedDate, // Store in 'MM-dd-yyyy' format
            count: dateEntry.count,
          };
        });
      this.setState({
        userOrgMetadata,
        allDates,
        availableDates,
        isLoading: false,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ isLoading: false });
    }
  }

  async componentDidMount() {
    await this.fetchRegistrationMetaData();
    if (this.props.profile.orgId) {
      await this.fetchExistingData();
      const existingData = this.state.registrationData;
      if (existingData) {
        const dates = this.state.registrationData.dates;
        const hubAmount = this.state.registrationData.hubAmount;
        let paymentOption = this.state.registrationData.paymentOption;

        switch (paymentOption) {
          case 'qh':
            paymentOption = 'qardan hasana application';
            break;
          case 'onecheque':
            paymentOption = 'one cheque';
            break;
          case 'twocheque':
            paymentOption = 'two cheques';
            break;
          default:
            break;
        }

        // Calculate dateCount based on hubAmount
        const ranges = [
          { min: 0, max: 2350, count: 0 },
          { min: 2350, max: 4700, count: 1 },
          { min: 4700, max: 7050, count: 2 },
          { min: 7050, max: 9400, count: 3 },
          { min: 9400, max: 11750, count: 4 },
          { min: 11750, max: 14100, count: 5 },
          { min: 14100, max: 16450, count: 6 },
          { min: 16450, max: 18800, count: 7 },
          { min: 18800, max: 21150, count: 8 },
          { min: 21150, max: 23500, count: 9 },
          { min: 23500, max: 25850, count: 10 },
        ];

        const amount = parseFloat(hubAmount);
        let count = 0; // Default count
        for (let range of ranges) {
          if (amount >= range.min && amount < range.max) {
            count = range.count;
            break; // Exit the loop once the correct range is found
          }
        }

        // Adjust availableDates based on registrationData.dates
        const updatedAvailableDates = this.state.availableDates.map((availableDate) => {
          const matchingDate = dates.find((dateEntry) => dateEntry.value === availableDate.value);
          if (matchingDate) {
            return {
              ...availableDate,
              count: Math.max(0, availableDate.count - 1), // Decrease count by 1, ensuring it doesn't go below 0
            };
          }
          return availableDate; // No change if no match found
        });

        // Update the state with adjusted availableDates and other fetched data
        this.setState(prevState => ({
          dateEntries: dates || [],
          dateCount: count,
          availableDates: updatedAvailableDates,
          registrationData: {
            ...prevState.registrationData,
            paymentOption: paymentOption,
          },
        }));

      } else {
        // If no existing data, fall back to defaults
        this.setState({
          isLoading: false,
        });
      }

    } else {
      this.props.history.push("/dashboard"); // Redirect to Dashboard
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { zabihatCount = 0, zabihatMonths = [] } = this.state.registrationData || {};

    // Ensure prevState.registrationData exists before accessing its properties
    const prevZabihatCount = (prevState.registrationData && prevState.registrationData.zabihatCount) || 0;

    // Check if `zabihatCount` has changed
    if (prevZabihatCount !== zabihatCount) {
      // Avoid unnecessary state updates by checking if the lengths already match
      if (zabihatMonths.length !== zabihatCount) {
        this.setState((prevState) => {
          let updatedMonths = [...zabihatMonths];

          // Trim or expand the array to match `zabihatCount`
          if (zabihatCount < updatedMonths.length) {
            updatedMonths = updatedMonths.slice(0, zabihatCount);
          } else {
            while (updatedMonths.length < zabihatCount) {
              updatedMonths.push(''); // Fill with empty strings
            }
          }

          return {
            registrationData: {
              ...prevState.registrationData,
              zabihatMonths: updatedMonths,
            },
          };
        });
      }
    }
  }

  handleDialogClose() {
    this.setState({ showDialog: false });

    if (this.state.successMessage) {
      // Navigate to dashboard if the operation was successful
      this.props.history.push('/dashboard'); // Use navigate('/dashboard') for `react-router-dom v6+`
    }

    if (this.state.errorMessage) {
      // Optionally perform actions for error handling
      console.error('Error occurred:', this.state.errorMessage); // Log the error for debugging
      // You can also reset specific states if needed
    }
  }


  shouldDisableDate = (date) => {
    if (!isValid(date)) return true;

    const formattedDate = format(date, 'MM-dd-yyyy');

    const isAlreadySelected = this.state.dateEntries.some((entry) => entry.value === formattedDate);

    const isAvailable = this.state.availableDates.some(
      (entry) => entry.value === formattedDate && entry.count > 0
    );

    return isAlreadySelected || !isAvailable;
  };

  renderDay = (day, selectedDate, dayInCurrentMonth) => {
    const { classes, dateEntries } = this.props; // Ensure classes and dateEntries are accessed from props
    const foundEntry = dateEntries.find(
      (entry) => entry.value === format(day, 'yyyy-MM-dd')
    );
    const count = foundEntry ? foundEntry.count : 0;
    const isAvailable = foundEntry && count > 0;

    return (
      <Badge
        key={day.toString()}
        badgeContent={count > 0 ? count : null}
        classes={{ badge: classes.badge }}
      >
        <div
          className={`${classes.day} ${isAvailable ? classes.availableDay : classes.unavailableDay}`}
        >
          {format(day, 'd')}
        </div>
      </Badge>
    );
  };

  render() {
    const { registrationData, dateEntries, showDialog, successMessage, errorMessage, registrationMessage, userOrgMetadata, dateCount, progressStep } = this.state;
    const { isBusyCommunicating } = this.props;
    const steps = ["Submit Registration", "Under Review", "Approved"]; // Define progress steps

    if (this.state.isLoading && !registrationData) {
      return <Spinner active={true}>Loading...</Spinner>;
    }
    const isEditable = (this.props.profile.registrationStatus === "completed") ? true : false;

    const { classes } = this.props;  // Access classes prop from withStyles






    return (
      <Spinner active={isBusyCommunicating} >

        <div style={{ padding: 20 }}>
          <ProgressSteps steps={steps} currentStep={progressStep} />
          <div style={{ marginTop: 0 }}>
          </div>
        </div>

        {registrationMessage && (
          <div className="blurb-message" style={{ textAlign: 'center' }}>
            {registrationMessage}
          </div>
        )}


        <div className="container-main row">
          <Paper className="Registration-paper col-sm-12 col-lg-5">
            <CssBaseline />
            <Grid container justify="space-evenly" alignItems="center">
              <Avatar>
                <PersonIcon />
              </Avatar>
              {/* #################################################################################### */}

              <Typography
                className="col-sm-12 col-lg-12"
                component="h1"
                variant="h5"
                align="center"
              >
                Registration - 1446/47H
              </Typography>

              <div className="blurb-message" style={{ textAlign: 'center' }}>
                For any questions please reach out to fmbfinance@mississaugajamaat.com or Mushtaq Baldiwala - (647) 521 5251
              </div>

              <form onSubmit={this.handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <FormControl margin="normal" fullWidth className="hub-select-container">
                      <Typography component="h1" variant="h5">
                        Thaali Niyaaz Hub
                      </Typography>
                      <Select
                        value={this.state.registrationData && this.state.registrationData.hubAmount || ''}
                        onChange={this.handleInputChange}
                        name="hubAmount"
                        id="hubAmount"
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        disabled={isEditable} // Disable if not editable
                      >
                        <MenuItem value="" disabled>Select Hub</MenuItem>
                        {userOrgMetadata[0] && userOrgMetadata[0].amount
                          ? userOrgMetadata[0].amount.map((amount, index) => (
                            <MenuItem key={index} value={amount}>
                              {amount}
                            </MenuItem>
                          ))
                          : []}
                      </Select>
                      {this.state.validationErrors.hubAmount && <span className="error-message">{this.state.validationErrors.hubAmount}</span>}
                      {dateCount >= 2 && (
                        <div className="blurb-message" style={{ textAlign: 'center' }}>
                          Select the same date twice (if available) if you would like to be the only niyaz karnar on that day
                        </div>

                      )}


                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Comment"
                      name="hubComment"
                      value={this.state.registrationData && this.state.registrationData.hubComment || ''}
                      onChange={this.handleInputChange}
                      fullWidth
                      variant="outlined"
                      disabled={isEditable} // Disable if not editable
                    />
                  </Grid>
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Select Thaali Niyaaz Day
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        name="dateOfThaali"
                        variant="outlined"
                        format="dd/MM/yyyy" // Display format in the picker
                        margin="normal"
                        value={new Date()} // Use valid Date object
                        disabled={this.state.dateCount === 0 || isEditable} // Disable if no dates are available
                        shouldDisableDate={(date) => {
                          if (!isValid(date)) return true; // Ensure date is valid
                          const { availableDates, dateEntries } = this.state;

                          // Convert the selected date to MM-dd-yyyy format
                          const formattedDate = format(date, 'MM-dd-yyyy');

                          // Check if the date is already selected
                          const isAlreadySelected = dateEntries.some((entry) => entry.value === formattedDate);

                          // Check if the date exists in availableDates with count > 0
                          const isAvailable = availableDates.some(
                            (entry) => entry.value === formattedDate && entry.count > 0
                          );

                          return isAlreadySelected || !isAvailable;
                        }}
                        onChange={(date) => {
                          if (!isValid(date)) {
                            console.error('Invalid date selected');
                            return;
                          }
                          this.handleDateSelection(date); // Handle the selected date
                        }}
                      />

                    </MuiPickersUtilsProvider>
                    {this.state.validationErrors.dates && <span className="error-message">{this.state.validationErrors.dates}</span>}
                  </Grid>
                  {dateCount > 0 && (
                    <Grid item>
                      <Typography component="h1" variant="h6" className='blurb-message'>
                        <span className='label'>Eligible Dates for Selection: </span>
                        <span className='date-count'>{dateCount}</span>
                      </Typography>
                      <Typography component="h1" variant="h5">Selected Dates:</Typography>
                      <Grid container direction="column" spacing={1}>
                        {this.state.dateEntries.slice(0, dateCount).map((date, index) => (
                          <Grid item key={index}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => this.handleDateSelection(new Date(date.value))}
                            >
                              {date.value}
                            </Button>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => this.handleDateDelete(date.value)}
                              style={{ marginLeft: 10 }}
                              disabled={isEditable}
                            >
                              Delete
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}



                  <Grid item>
                    <FormControl margin="normal" fullWidth variant="outlined">
                      <Typography component="h1" variant="h5">
                        Moulana Hamza A.S. Zabihat Count
                      </Typography>
                      <Select
                        value={this.state.registrationData &&
                          this.state.registrationData.zabihatCount !== undefined &&
                          this.state.registrationData.zabihatCount !== null
                          ? this.state.registrationData.zabihatCount
                          : ""
                        } // Ensure value is connected to state
                        onChange={this.handleInputChange}
                        name="zabihatCount"
                        id="zabihatCount"
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        disabled={isEditable}
                      >
                        <MenuItem value="" disabled>Select Zabihat Count</MenuItem>
                        {Array.from({ length: 11 }, (_, index) => ( // Generates numbers from 0 to 15
                          <MenuItem key={index} value={index}>
                            {index}
                          </MenuItem>
                        ))}

                      </Select>
                      <div className='blurb-message'>
                        <ul>
                          <li>Zabihat Hub is $275.</li>
                          <li>Zabihat payment can be done by cheque dated for 22nd March 2025 or 5th July 2025.
                          </li>
                        </ul>

                      </div>
                      {this.state.validationErrors.zabihatCount && <span className="error-message">{this.state.validationErrors.zabihatCount}</span>}

                    </FormControl>
                  </Grid>

                  {/* Render month selection dynamically based on zabihatCount */}
                  {this.state.registrationData &&
                    this.state.registrationData.zabihatCount > 0 && (
                      <Grid item>
                        <Typography variant="h6">
                          Select preferred Zabihat month(s):
                        </Typography>
                        <Grid container direction="column" spacing={2}>
                          {Array.from(
                            { length: this.state.registrationData.zabihatCount }
                          ).map((_, index) => (
                            <Grid item key={index}>
                              <FormControl fullWidth variant="outlined">
                                <Select
                                  value={
                                    Array.isArray(this.state.registrationData.zabihatMonths) &&
                                      this.state.registrationData.zabihatMonths[index]
                                      ? this.state.registrationData.zabihatMonths[index]
                                      : ''
                                  }
                                  onChange={(event) => this.handleMonthChange(event, index)}
                                  displayEmpty
                                  disabled={isEditable}
                                >
                                  <MenuItem value="" disabled>
                                    Select Month
                                  </MenuItem>
                                  {[
                                    'Moharram',
                                    'Safar',
                                    'Rabi-ul-Awwal',
                                    'Rabi-ul-Akhar',
                                    'Jamadi-ul-Awwal',
                                    'Jamadi-ul-Akhar',
                                    'Rajab',
                                    'Shabaan',
                                    'Ramadaan',
                                    'Shawwal',
                                    'Zilqad',
                                    'Zilhaj',
                                  ].map((month, i) => (
                                    <MenuItem key={i} value={month}>
                                      {month}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {this.state.validationErrors.zabihatMonths && <span className="error-message">{this.state.validationErrors.zabihatMonths}</span>}

                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Grid>

                        <FormControl fullWidth variant="outlined">
                          <Typography component="h1" variant="h5">Select a Payment Method:</Typography>

                          <Select
                            name="paymentOption"
                            id="paymentOption"
                            value={this.state.registrationData && this.state.registrationData.paymentOption || ''} // Dynamically access each payment method value
                            onChange={this.handleInputChange}
                            displayEmpty
                            disabled={isEditable}
                          >
                            <MenuItem value="" disabled>Select Payment Method</MenuItem>
                            {[
                              'One Cheque',
                              'Two Cheques',
                              'Qardan Hasana Application',
                            ].map((method, i) => (
                              <MenuItem key={i} value={method.toLowerCase()}>
                                {method}
                              </MenuItem>
                            ))}
                          </Select>
                          <div className='blurb-message'>
                            * For Cheque Payment Method please opt for:
                            <ul>
                              <li>One Cheque (Paid on Lailatul Qadr Dated 22nd March 2025)</li>
                              <li>Two Cheques (Paid on Lailatul Qadr Dated 22nd March and Ashura Dated 5th July 2025)</li>
                            </ul>
                          </div>

                          {this.state.validationErrors.paymentOption && <span className="error-message">{this.state.validationErrors.paymentOption}</span>}

                        </FormControl>
                      </Grid>

                    </Grid>
                  </Grid>


                  <Grid item>
                    {!isEditable && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ backgroundColor: '#f40158' }}
                      >
                        Save
                      </Button>
                    )}

                  </Grid>
                </Grid>

                {/* Dialog Box */}
                <Dialog open={showDialog} onClose={() => this.handleDialogClose()}>
                  <DialogTitle>{successMessage ? 'Success' : 'Error'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {successMessage || errorMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleDialogClose()}
                      color="primary"
                      autoFocus
                    >
                      Okay
                    </Button>
                  </DialogActions>
                </Dialog>


              </form>
            </Grid>
          </Paper>
        </div>
      </Spinner >
    );

  }
}
const mapStateToProps = (state: AppState) => ({
  registrationMetaData: state.registrationMetadata,
  registrationData: state.registrationData,
  isBusyCommunicating: state.isBusyCommunicating,
  profile: state.profile,
});

const mapDispatchToProps = {
  getRegistrationMetaData: registrationMetadataAction.getRegistrationMetaData,
  updateRegistrationData: registrationDataAction.updateRegistrationData,
  getRegistrationDatabyId: registrationDataAction.getRegistrationDatabyId,
};

// Combine connect and withStyles
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Registration));



export const TOKEN_API_ENPOINT = `${process.env.REACT_APP_API_ENDPOINT}user/token`;
export const UPDATE_THALI_SCHEDULE_ENDPOINT = (subscriberId: string) =>
  `${process.env.REACT_APP_API_ENDPOINT}subscriber/schedule/override/${subscriberId}`;
export const GET_THALI_SCHEDULE_ENDPOINT = (subscriberId: string) =>
  `${process.env.REACT_APP_API_ENDPOINT}subscriber/schedule/${subscriberId}`;
export const DELETE_THALI_SCHEDULE_ENDPOINT = (
  subscriberId: string,
  startDate: string | Date
) =>
  `${process.env.REACT_APP_API_ENDPOINT}subscriber/schedule/override/${subscriberId}/${startDate}`;
export const USER_PROFILE_ENDPOINT = (username: string | null) =>
  `${process.env.REACT_APP_API_ENDPOINT}subscriber/profile${username ? '/' + username : ''
  }`;
export const GET_SCHEDULLE_MONTH_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}schedule/month`;

export const GET_MEAL_COUNT_SECTORWISE_DAILY_ENDPOINT = (
  selectedDate: string
) =>
  `${process.env.REACT_APP_API_ENDPOINT}admin/sector/meal/count/${selectedDate}`;

export const V2_GET_MEAL_COUNT_SECTORWISE_DAILY_ENDPOINT = (
  selectedDate: string
) =>
  `${process.env.REACT_APP_API_ENDPOINT}admin/v2/sector/meal/count/${selectedDate}`;

export const ADD_REVIEW = `${process.env.REACT_APP_API_ENDPOINT}review`;

export const GET_REVIEW_BY_USERID_SCHEDULEDATE = (
  username: string,
  selectedDate: string
) => `${process.env.REACT_APP_API_ENDPOINT}review/${username}/${selectedDate}`;

export const GET_REVIEWS_BY_SCHEDULEDATE = (selectedDate: string) =>
  `${process.env.REACT_APP_API_ENDPOINT}review/${selectedDate}`;

//export default let logConfig = () =>  console.log ( `'TOKEN_API_ENPOINT': ${TOKEN_API_ENPOINT}`  );

export const GET_REVIEW_BY_USERID_FOR_DATERANGE = (
  username: string,
  fromDate: string,
  toDate: string
) =>
  `${process.env.REACT_APP_API_ENDPOINT}review/${username}/${fromDate}/${toDate} `;

export const GET_CONTACTINFO_CONTENT = () =>
  `${process.env.REACT_APP_API_ENDPOINT}content/contactInfo`;

export const GET_ALL_SUBSCRIBERINFO = () =>
  `${process.env.REACT_APP_API_ENDPOINT}subscriber/schedule/getAll`;

export const GET_SECTORS_CONTENT = () =>
  `${process.env.REACT_APP_API_ENDPOINT}content/sectors`;

export const UPDATE_SUBSCRIBER_SCHEUDULE = () =>
  `${process.env.REACT_APP_API_ENDPOINT}subscriber/schedule`;

export const UPDATE_SECTOR_ENDPOINT = () => `${process.env.REACT_APP_API_ENDPOINT}subscriber/zone`;

export const UPDATE_THALISIZE_ENDPOINT = () => `${process.env.REACT_APP_API_ENDPOINT}subscriber/thalisize`;

export const QR_CODE_ENDPOINT = (
  orgId: string
) =>
  `${process.env.REACT_APP_API_ENDPOINT}admin/qrcode/${orgId}`;

//write api to get user registration details
export const GET_USER_REGISTRATION_METADATA = () =>
  `${process.env.REACT_APP_API_ENDPOINT}registration/metadata`;

//update api to update user registration details
export const GET_USER_REGISTRATION = () =>
  `${process.env.REACT_APP_API_ENDPOINT}registration/registration`;

export const UPDATE_USER_REGISTRATION = () =>
  `${process.env.REACT_APP_API_ENDPOINT}registration/register`;

export const SYNC_REGISTRATION_DATA = () =>
  `${process.env.REACT_APP_API_ENDPOINT}registration/sync`;

export const GET_USER_REGISTRATION_ORGID = (
  orgId: string
) =>
  `${process.env.REACT_APP_API_ENDPOINT}registration/registration/${orgId}`;

export const DELETE_USER_REGISTRATION_ORGID = (
  orgId: string
) =>
  `${process.env.REACT_APP_API_ENDPOINT}registration/registration/${orgId}`;

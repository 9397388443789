import React from "react";

const ProgressSteps = ({ steps, currentStep }) => {
    return (
        <div style={styles.container}>
            {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                const isActive = index === currentStep;

                return (
                    <div key={index} style={styles.stepContainer}>
                        <div
                            style={{
                                ...styles.circle,
                                backgroundColor: isCompleted ? "#f40158" : isActive ? "#fff" : "#e0e0e0",
                                border: `2px solid ${isCompleted || isActive ? "#f40158" : "#e0e0e0"}`,
                                color: isCompleted ? "#fff" : isActive ? "#f40158" : "#000",// Explicitly set white for "✔"
                                fontFamily: "Arial, sans-serif", // Use a consistent font to ensure uniform rendering
                            }}
                        >
                            {isCompleted ? "✔" : index + 1}
                        </div>
                        <span style={styles.stepLabel}>{step}</span>
                        {index < steps.length - 1 && (
                            <div
                                style={{
                                    ...styles.line,
                                    backgroundColor: index < currentStep ? "#f40158" : "#e0e0e0",
                                }}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const styles = {
    container: {
        display: "grid", // Use grid layout for the container
        gridTemplateColumns: "repeat(3, 1fr)", // 3 equal-width columns
        gap: "1rem", // Space between the steps
        padding: "0 1rem", // Adds horizontal padding for better spacing
        boxSizing: "border-box" as 'border-box', // Ensures padding is included in width calculations
        overflow: "hidden", // Ensures any overflow is hidden
        width: "100%", // Full width for responsiveness
        margin: "0 auto", // Centers the container horizontally
    },

    stepContainer: {
        display: "flex", // Use flexbox to align items vertically
        flexDirection: "column" as 'column', // Stack the elements vertically (circle, label, line)
        alignItems: "center", // Centers the content in each step
        justifyContent: "flex-start", // Aligns everything vertically in the center
        width: "100%", // Ensure the step container takes full width
    },

    circle: {
        width: "5rem", // Fixed size for the circle
        height: "5rem", // Fixed size for the circle
        display: "flex",
        alignItems: "center",
        justifyContent: "center", // Centers text inside the circle
        borderRadius: "50%", // Ensures it's a perfect circle
        backgroundColor: "#f0f0f0", // Example background color for the circle
        boxSizing: "border-box" as 'border-box', // Prevents overflow issues due to padding
        fontSize: "2rem", // Font size for the text inside the circle
    },

    line: {
        width: "100%", // Makes the line take full width within the grid cell
        height: "2px", // Defines the height of the line
        backgroundColor: "#ccc", // Light color for the line
        marginTop: "1rem", // Adds space above the line
    },

    stepLabel: {
        fontSize: "1rem", // Font size for the step label
        textAlign: "center" as 'center', // Centers the label text
        paddingTop: "0.5rem", // Adds space above the label
    },
};

export default ProgressSteps;

import axios from 'axios';
import {
    GET_REGISTRATION_METADATA, API_COMMUNICATION_INPROGRESS, API_COMMUNICATION_DONE, AUTH_ERROR, UPDATE_USER_REGISTRATION_WITH_METADATA
} from './actionType';
import { GET_USER_REGISTRATION_METADATA } from '../api/API';

export const getRegistrationMetaData = (
    onErrorCallback: Function
) => async (dispatch: Function) => {
    try {
        dispatch({ type: API_COMMUNICATION_INPROGRESS })
        const response = await axios.get(GET_USER_REGISTRATION_METADATA());
        dispatch({ type: GET_REGISTRATION_METADATA, payload: response.data });
        dispatch({ type: API_COMMUNICATION_DONE })
    } catch (e) {
        onErrorCallback && onErrorCallback();
        dispatch({ type: AUTH_ERROR, payload: 'Cannot Fetch Registraton Metada Details' });
    }
};

import axios, { AxiosError } from 'axios';
import {
    FETCH_USER_REGISTRATION,
    API_COMMUNICATION_INPROGRESS,
    API_COMMUNICATION_DONE,
    AUTH_ERROR,
    UPDATE_REGISTRATION,
    DELETE_REGISTRATION_DATA,
    FETCH_ONLY_USER_REGISTRATION,
} from './actionType';
import {
    GET_USER_REGISTRATION,
    GET_USER_REGISTRATION_ORGID,
    UPDATE_USER_REGISTRATION,
    DELETE_USER_REGISTRATION_ORGID,
    SYNC_REGISTRATION_DATA,
} from '../api/API';
import { RegistrationData } from '../type/Type';

export const getRegistrationData = (
    onErrorCallback: Function
) => async (dispatch: Function) => {
    try {
        dispatch({ type: API_COMMUNICATION_INPROGRESS })
        const response = await axios.get(GET_USER_REGISTRATION());
        dispatch({ type: FETCH_USER_REGISTRATION, payload: response.data });
        dispatch({ type: API_COMMUNICATION_DONE })
    } catch (e) {
        onErrorCallback && onErrorCallback();
        dispatch({ type: AUTH_ERROR, payload: 'Cannot Fetch Registraton Metada Details' });
    }
};

export const getRegistrationDatabyId = (
    orgId: string,
    onErrorCallback: Function
) => async (dispatch: Function) => {
    try {
        dispatch({ type: API_COMMUNICATION_INPROGRESS })
        const response = await axios.get(GET_USER_REGISTRATION_ORGID(orgId));
        dispatch({ type: FETCH_ONLY_USER_REGISTRATION, payload: response.data });
        dispatch({ type: API_COMMUNICATION_DONE })
    } catch (e) {
        onErrorCallback && onErrorCallback();
        dispatch({ type: AUTH_ERROR, payload: 'Cannot Fetch Registraton Metada Details by OrdId' });
    }
};



export const updateRegistrationData = (
    registrationData: RegistrationData,
    onSuccessCallback: Function,
    onErrorCallback: Function
) => async (dispatch: Function) => {
    try {
        const response = await axios.post(UPDATE_USER_REGISTRATION(), registrationData);

        if (response && response.status === 200) {
            dispatch({ type: UPDATE_REGISTRATION, payload: registrationData });
            // Pass the full response object to the success callback
            onSuccessCallback(response.data);
        } else {
            // Pass the error details from the response, if available
            onErrorCallback(response && response.data || { message: 'An unexpected error occurred' });
        }
    } catch (e) {
        onErrorCallback((e as Error).message || 'Failed to submit registration');
        dispatch({ type: AUTH_ERROR, payload: 'Cannot Fetch Registration Metadata Details' });
    }
};

export const syncRegistrationData = () => async (dispatch) => {
    try {
        const response = await fetch(SYNC_REGISTRATION_DATA(), { method: 'GET' });
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SYNC_REGISTRATION_DATA, payload: data });
        } else {
            // Handle error response
            const errorMessage = await response.text();
            console.error('Error syncing registration data:', errorMessage);
        }
    } catch (error) {
        console.error('Error syncing registration data:', error);
    }
};


export const deleteRegistrationData = (orgId, callback) => async (dispatch) => {
    try {
        const response = await axios.delete(DELETE_USER_REGISTRATION_ORGID(orgId));
        if (response && response.status === 200) {
            dispatch({ type: DELETE_REGISTRATION_DATA, payload: orgId });
            if (callback) callback();
        } else {
            // Handle error response
            const errorMessage = response && response.data ? response.data : 'An unexpected error occurred';
            console.error('Error deleting registration data:', errorMessage);
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response && axiosError.response.data || axiosError.message;
        console.error('Error deleting registration data:', errorMessage);
    }
};

export const updateFinanceRegistrationData = (
    registrationData: RegistrationData,
    onSuccessCallback: Function,
    onErrorCallback: Function
) => async (dispatch: Function) => {
    try {
        const response = await axios.put(UPDATE_USER_REGISTRATION(), registrationData);

        if (response && response.status === 200) {
            dispatch({ type: UPDATE_REGISTRATION, payload: registrationData });
            // Pass the full response object to the success callback
            onSuccessCallback(response.data);
        } else {
            // Pass the error details from the response, if available
            onErrorCallback(response && response.data || { message: 'An unexpected error occurred' });
        }
    } catch (e) {
        onErrorCallback((e as Error).message || 'Failed to submit registration');
        dispatch({ type: AUTH_ERROR, payload: 'Cannot Fetch Registration Metadata Details' });
    }
};